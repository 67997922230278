<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')" />
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal">
          <p class="title">Condições Pré-Aprovadas</p>
          <p><span class="font-bold">Valor líquido: </span>{{ formatMoney(proposal.preAprovado.valor) }}</p>
          <p><span class="font-bold">Prazo: </span>{{ proposal.preAprovado.prazo }} meses</p>
          <p><span class="font-bold">Taxa mensal: </span>{{ proposal.preAprovado.taxaMensal.toFixed(2) }}%</p>
          <p><span class="font-bold">Valor parcela: </span>{{ formatMoney(proposal.preAprovado.valorParcela) }}</p>
          <hr />

          <div v-if="proposal.aprovadoComite_Valor > 0">
            <p class="title">Aprovado em Comitê</p>
            <p><span class="font-bold">Valor líquido: </span>{{ formatMoney(proposal.aprovadoComite_Valor) }}</p>
            <p><span class="font-bold">Prazo: </span>{{ proposal.aprovadoComite_Prazo }} meses</p>
            <p><span class="font-bold">Taxa mensal: </span>{{ proposal.aprovadoComite_Taxa.toFixed(2) }}%</p>
            <p><span class="font-bold">Valor parcela: </span>{{ formatMoney(proposal.aprovadoComite_Parcela) }}</p>
          </div>
          <hr />
          <div v-if="proposal.liberado_Valor > 0">
            <p class="title">Liberado</p>
            <p><span class="font-bold">Valor líquido: </span>{{ formatMoney(proposal.liberado_Valor) }}</p>
            <p><span class="font-bold">Prazo: </span>{{ proposal.liberado_Prazo }} meses</p>
            <p><span class="font-bold">Taxa mensal: </span>{{ proposal.liberado_Taxa.toFixed(2) }}%</p>
            <p><span class="font-bold">Valor parcela: </span>{{ formatMoney(proposal.liberado_Parcela) }}</p>
          </div>

          <v-button class="w-full flex justify-center mt-4" @click="confirmDialog = true">Preencher ficha e
            docs</v-button>
        </div>
      </div>
    </div>
    <confirm-dialog v-if="confirmDialog" @confirm="submit" @closeModal="confirmDialog = false" :loading="loading">
      <p class="title">Atenção</p>
      <p class="text">Deseja acessar o perfil do tomador {{ proposal.nomeEmpresa }}?</p>
      <p class="text">Você será deslogado da conta de parceiro para isso.</p>
    </confirm-dialog>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import ConfirmDialog from '@/components/Modal/ConfirmDialog'
import MoneyFilter from '@/mixins/moneyFilter'

import Dashboard from '@/services/Partner/Dashboard'

export default {
  props: ['proposal'],
  components: { VButton, ConfirmDialog },
  mixins: [MoneyFilter],
  data() {
    return {
      confirmDialog: false,
      loading: false
    }
  },
  methods: {
    async submit() {
      try {
        this.loading = true
        const response = (await Dashboard.auth(this.proposal.codigo)).data.data
        response.isFromPartner = true

        await this.$store.dispatch('setLogin', response)
        this.$router.push('/tomador/perfil')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-full bg-white z-50 flex flex-col justify-center pb-6 pt-8 rounded-lg
  @screen lg
    @apply w-1/2

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.content_modal
  @apply flex flex-col w-full px-4
  @screen lg
    @apply px-8

.title
  @apply font-bold text-lg text-black text-center uppercase text-primary mb-4
  @screen lg
    @apply text-lg

hr
  @apply mb-4 mt-4

</style>
