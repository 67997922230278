<template>
  <div class="main-content">
    <transition name="fade">
      <detail-proposal-modal v-if="detailModal" :proposal="detailModal" @closeModal="detailModal = null">
      </detail-proposal-modal>
    </transition>
    <div class="overflow-x-auto lg:overflow-x-hidden">
      <div class="flow-root">
        <select v-model="filtroStatus">
          <option value="All">Todos</option>
          <option value="Open">Abertos</option>
          <option value="Lost">Negados</option>
          <option value="Won">Concedidos</option>
        </select>

        <select v-model="filtroStatusPedido">
          <option value="All">Todos</option>
          <option value="Buscar">Buscar por CNPJ, Razão Social ou Nome Fantasia</option>
          <option value="PRÉ-APROVADO">Pré-Aprovado</option>
          <option value="FICHA CADASTRAL PENDENTE">Ficha cadastral incompleta</option>
          <option value="FICHA DA EMPRESA PENDENTE">Ficha PJ incompleta</option>
          <option value="FICHA DO SOLICITANTE PENDENTE">Ficha PF solicitante Incompleta</option>
          <option value="CADASTRO SÓCIOS INCOMPLETO">Cadastro sócios incompleto</option>
          <option value="FALTA CADASTRO AVALISTA">Falta avalista</option>
          <option value="FALTAM DOCS">Fatam docs</option>
          <option value="DOCS EM ANÁLISE">Docs em análise</option>
          <option value="AGUARDANDO AGENDAMENTO VISITA">Aguardando agendamento visita</option>
          <option value="VISITA AGENDADA">Visita agendada</option>
          <option value="VISITA REALIZADA - EM COMITÊ">Visita realizada</option>
          <option value="AGUARDANDO ASSINATURA DOS SÓCIOS">Aguardandoa assinatura dos sócios</option>
          <option value="EM FORMALIZAÇÃO">Em formalização</option>
          <option value="EM FORMALIZAÇÃO - FALTAM DOCS">Em formalização - Faltam Docs</option>
          <option value="CONCEDIDO">Concedido</option>

        </select>

        <input type="text" v-model="filtroBusca" v-if="this.filtroStatusPedido == 'Buscar'"
          placeholder="Buscar cliente por CNPJ, Razão Social ou Nome Fantasia" />

        <div class="float-right mr-2">
          <a v-if="!gerandoPlanilha" href="javascript://" v-on:click="gerarPlanilha">Exportar XLS</a>
          <span v-show="gerandoPlanilha">Gerando planilha, aguarde...</span>
        </div>
      </div>
      <vuetable ref="vuetable" :fields="header" :api-mode="false" :data-manager="dataManager"
        :css="{ tableClass: 'proposals' }" :row-class="rowClass"
        :no-data-template="loading ? '' : 'Nenhum dado retornado'" @vuetable:row-clicked="detail">
        <template v-slot:data="props">
          <div class="flex items-center justify-center">
            <p>{{ $moment(props.rowData.dataInclusao).format('DD/MM/YYYY [às] HH:mm:ss') }}</p>
          </div>
        </template>
        <template v-slot:nomeEmpresa="props">
          <div class="flex items-center">
            <p class="text-sm font-bold text-left">{{ props.rowData.nomeEmpresa.toUpperCase() }}</p>
          </div>
        </template>
        <template v-slot:cnpj="props">
          <div class="flex items-center justify-center">
            <p class="whitespace-no-wrap">{{ props.rowData.cnpj }}</p>
          </div>
        </template>
        <template v-slot:solicitado="props">
          <div class="flex items-center justify-center">
            <p class="whitespace-no-wrap">{{ `${formatMoney(props.rowData.valorEmprestimoDesejado)} em
                          ${props.rowData.prazoDesejado}x`
            }}
            </p>
          </div>
        </template>
        <template v-slot:preAprovado="props">
          <div class="flex items-center justify-center">
            <p class="whitespace-no-wrap" v-if="props.rowData.preAprovado && props.rowData.preAprovado.valor > 0">
              {{ formatMoney(props.rowData.preAprovado.valor) }} <br /> em {{ `${props.rowData.preAprovado.prazo}x` }}
            </p>
          </div>
        </template>
        <template v-slot:aprovadoComite="props">
          <div class="flex items-center justify-center">
            <p class="whitespace-no-wrap" v-if="props.rowData.aprovadoComite_Valor > 0"> {{
              formatMoney(props.rowData.aprovadoComite_Valor) }} <br /> em {{ `${props.rowData.aprovadoComite_Prazo}x` }}
            </p>
          </div>
        </template>

        <template v-slot:concedido="props">
          <div class="flex items-center justify-center">
            <p class="whitespace-no-wrap" v-if="props.rowData.liberado_Valor > 0">{{
              formatMoney(props.rowData.liberado_Valor) }} <br /> em {{ `${props.rowData.liberado_Prazo}x` }}</p>
          </div>
        </template>
        <template v-slot:status="props">
          <div class="flex items-center justify-center">
            {{ props.rowData.status }}
          </div>
        </template>
      </vuetable>
    </div>
    <div v-if="loading" class="w-full flex flex-col justify-center items-center my-4">
      <span class="fa fa-spinner fa-spin text-2xl text-secondary"></span>
    </div>
    <div class="flex justify-end">
      <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="paginationCss">
      </vuetable-pagination>
    </div>
  </div>
</template>

<script>
import { VuetablePagination } from 'vuetable-2'
import MoneyFilter from '@/mixins/moneyFilter'
import DetailProposalModal from '@/components/Partner/Modal/DetailProposalModal'

import Dashboard from '@/services/Partner/Dashboard'

export default {
  name: 'partner-proposals-table',
  components: { VuetablePagination, DetailProposalModal },
  mixins: [MoneyFilter],
  data() {
    return {
      loading: true,
      gerandoPlanilha: false,
      detailModal: null,
      filtroStatus: "All",
      filtroStatusPedido: "All",
      filtroBusca: "",
      proposals: [],
      pagination: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0
      },
      header: [
        {
          name: '__slot:data',
          title: 'Data',
          width: '10%'
        },
        {
          name: '__slot:nomeEmpresa',
          title: 'Empresa',
          width: '50%'
        },
        {
          name: '__slot:cnpj',
          title: 'CNPJ',
          width: '15%'
        },
        {
          name: '__slot:solicitado',
          title: 'Solicitado',
          width: '15%'
        },
        {
          name: '__slot:preAprovado',
          title: 'Pré-Aprovado',
          width: '15%'
        },
        {
          name: '__slot:aprovadoComite',
          title: 'Aprovado Comitê',
          width: '15%'
        },
        ,
        {
          name: '__slot:concedido',
          title: 'Concedido',
          width: '15%'
        },
        {
          name: '__slot:status',
          title: 'Status',
          width: '10%'
        }
      ],
      paginationCss: {
        wrapperClass: 'border rounded-lg flex m-2 text-secondary',
        activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
        disabledClass: 'text-gray-300 cursor-not-allowed',
        pageClass: 'pagination_page_class',
        linkClass: 'pagination_page_class',
        icons: {
          first: 'fas fa-angle-double-left',
          prev: 'fas fa-angle-left',
          next: 'fas fa-angle-right',
          last: 'fas fa-angle-double-right'
        }
      }
    }
  },
  methods: {
    detail(item) {
      if (!!item.preAprovado && item.status !== 'NEGADO') this.detailModal = item
    },
    rowClass(item) {
      if (!!item.preAprovado && item.status !== 'NEGADO') return 'cursor-pointer'
      return item.status === 'NEGADO' && 'text-red-600'
    },
    onChangePage(page) {
      switch (true) {
        case page === 'next':
          return this.getProposals(this.pagination.current_page + 1)
        case page === 'prev':
          return this.getProposals(this.pagination.current_page - 1)
        default:
          return this.getProposals(page)
      }
    },

    async gerarPlanilha() {
      this.gerandoPlanilha = true;
      try {
        const res = await Dashboard.getXls();
        this.saveBlob(res.data, `solicitacoes-${this.$moment().format('DD-MM-YYYY-HH-mm-ss')}`);
      } catch { }
      finally {
        this.gerandoPlanilha = false;
      }
    },

    saveBlob(blob, fileName) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },

    dataManager(sortOrder, pagination) {
      if (this.proposals.length < 1) return
      return {
        pagination: this.pagination,
        data: this.proposals
      }
    },
    async getProposals(page) {
      if (page === this.pagination.current_page || page < 1 || page > this.pagination.last_page) return

      if (this.filtroStatusPedido === 'Buscar' && this.filtroBusca.trim().length < 3) return;

      try {
        this.loading = true
        this.$refs.vuetable && this.$refs.vuetable.resetData()
        this.proposals = []
        const response = (await Dashboard.getProposals(page, this.filtroStatus == "All" ? null : this.filtroStatus, this.filtroBusca, this.filtroStatusPedido == "All" || this.filtroStatusPedido == "Buscar" ? null : this.filtroStatusPedido)).data.data
        this.proposals = response.itens
        this.pagination = {
          current_page: page || 1,
          last_page: response.paginas,
          total: response.totalItens,
          per_page: response.itensPorPagina
        }
        this.$refs.vuetable && this.$refs.vuetable.reload()
        this.$refs.pagination.setPaginationData(this.pagination)

        console.log(this.pagination)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.getProposals()
  },
  watch: {
    "filtroStatus": async function () {
      await this.getProposals()
    },
    "filtroStatusPedido": async function () {
      if (this.filtroStatusPedido != 'Buscar') {
        this.filtroBusca = null;
      }
      await this.getProposals()
    },
    "filtroBusca": async function () {
      await this.getProposals()
    }
  }
}
</script>

<style lang="sass" scoped>
.main-content
  @apply w-full h-full bg-white border shadow rounded-lg

.title
  @apply text-gray-700 text-sm text-center font-bold mb-2 ml-4
  @screen lg
    @apply text-left text-base

</style>

<style lang="sass">
.proposals
  @apply w-full text-sm
  thead tr th
    @apply p-4 text-xs text-gray-600
    background: #ECECEC
  tr
    @apply border-b border-gray-300
    &:nth-child(even)
      @apply bg-gray-100
    &:not(.text-red-600)
      @apply text-green-600
    &:hover
      @apply bg-gray-200
  td
    @apply px-4 py-2 text-center whitespace-normal
    &:first-child
      @apply w-104

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent

</style>
