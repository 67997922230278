<template>
  <div class="flex flex-col pb-8 lg:flex-row lg:flex-wrap w-full my-2">
    <div class="w-full flex items-center mt-4">
      <p class="title">Propostas</p>
    </div>
    <chave-pix></chave-pix>
    <proposals />
  </div>
</template>

<script>
import Proposals from '@/components/Partner/Proposals/Proposals'
import ChavePix from "@/components/Partner/ChavePix/ChavePix";

export default {
  name: 'partner-proposals',
  components: { Proposals,ChavePix }
}
</script>

<style lang="sass" scoped>

.title
  @apply font-bold text-xl text-secondary
  @screen lg
    @apply text-2xl

</style>
