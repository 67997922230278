import { render, staticRenderFns } from "./Proposals.vue?vue&type=template&id=58e702b5&scoped=true&"
import script from "./Proposals.vue?vue&type=script&lang=js&"
export * from "./Proposals.vue?vue&type=script&lang=js&"
import style0 from "./Proposals.vue?vue&type=style&index=0&id=58e702b5&lang=sass&scoped=true&"
import style1 from "./Proposals.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e702b5",
  null
  
)

export default component.exports