import api from '@/plugins/axios'
import axios from "axios";

const base = 'v1/site/parceiro/'
const cancelToken = axios.CancelToken.source();

export default {
    getProposalsCancelToken: cancelToken,
    getProposals(page, status, termoBusca, statusPedido) {
        return api.get(`${base}propostas`, { params: { pagina: page, status, termoBusca, statusPedido } })
    },
    getProposal(code) {
        return api.get(`${base}propostas/${code}`)
    },
    newProposal(data) {
        return api.post(`${base}propostas/incluir`, data, {
            headers: { 'painel-tutu': 'true' }
        });
    },
    getReasons() {
        return api.get('v1/site/emprestimo/motivos')
    },
    getXls() {
        return api.get(`${base}planilha`, {
            responseType: 'blob'  /* or responseType: 'arraybuffer'  */
        })
    },
    getFechamentos() {
        return api.get(`${base}fechamentos-mensais`)
    },
    auth(code) {
        return api.post(`${base}propostas/${code}/auth`)
    },
    setChavePix(chavePix) {
        return api.put(`${base}chave-pix`, { chavePix })
    },
    getChavePix(chavePix) {
        return api.get(`${base}chave-pix`)
    },
    postNF(data, cb) {
        return api.post(`${base}enviar-nota-fiscal`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: cb
        })
    },
    getVerNotaFiscal(fechamentoId) {
        return api.get(`${base}ver-nota-fiscal/${fechamentoId}`)
    },
    getVerComprovantePgto(fechamentoId) {
        return api.get(`${base}ver-comprovante-pagamento/${fechamentoId}`)
    },
    getNameRFB(cnpj) {
        return api.get(base + `razao-social-fantasia?cnpj=${cnpj}`)
    },
    consultaSCRCnpj(doc, selectedMonth, selectedYear) {
        return api.get(`${base}scr/pdf/cnpj?cnpj=${doc}&mes=${selectedMonth}&ano=${selectedYear}`, {
            responseType: "blob"
        });
    },
    consultaSCRCpf(doc, selectedMonth, selectedYear) {
        return api.get(`${base}scr/pdf/cpf?cpf=${doc}&mes=${selectedMonth}&ano=${selectedYear}`, {
            responseType: "blob"
        });
    },
    consultaSCRId(id) {
        return api.get(`${base}scr/pdf/cnpj?id=${id}`, {
            responseType: "blob"
        });
    },
    consultasSCR(pagina, itensPorPagina, documento) {
        if (!documento)
            documento = '';

        return api.get(`${base}scr?pagina=${pagina}&itensPorPagina=${itensPorPagina}&documento=${documento}`);
    }
}