<template>
  <div>
      <div class="w-full flex items-center mb-4">
      <p class="text" v-if="!chavePix && carregouChavePixPrimeiraVez">
        Você ainda não cadastrou sua chave PIX para receber as comissões,<a
          href="javascript://"
          class="text-primary"
          v-on:click="confirmDialog = true; chavePixAlterar = chavePix"
        >
          clique aqui</a
        >
        e cadastre.
      </p>

       <p class="text" v-if="chavePix && carregouChavePixPrimeiraVez">
        Chave PIX cadastrada para pagamento das comissões: {{chavePix}} - <a
          href="javascript://"
          class="text-primary"
          v-on:click="confirmDialog = true; chavePixAlterar = chavePix"
        >
          alterar</a
        >
      </p>
    </div>

     <confirm-dialog
      v-if="confirmDialog"
      @confirm="submitChavePix"
      @closeModal="confirmDialog = false"
      :loading="loading"
    >
      <p class="title text-center">Alterar chave PIX</p>
      <p class="subtitle text-center">Os pagamentos da comissões serão feitos através de transferências PIX.
      <br />
      <hr />
      <div class="text-center m-2 w-full">
        <v-input class="w-full" name="chavePix" v-model="chavePixAlterar" label="Chave PIX - CNPJ, Telefone, E-mail ou Chave Aleatória cadastrada no seu banco"></v-input>
      </div>
    </confirm-dialog>
  </div>
</template>

<script>

import Dashboard from '@/services/Partner/Dashboard'
import ConfirmDialog from "@/components/Modal/ConfirmDialog";
import VInput from "@/components/Inputs/Input";

export default {
  name: 'alterar-chave-pix',
  components: {  ConfirmDialog,VInput},
   data() {
    return { chavePix: null, confirmDialog: false, loading: false, chavePixAlterar : null, carregouChavePixPrimeiraVez : false };
  },
  async mounted() {
    await this.carregarChavePix();
  },
  methods: {
    async carregarChavePix() {
      this.chavePix = (await Dashboard.getChavePix()).data.data;
      this.carregouChavePixPrimeiraVez = true;
    },
    async submitChavePix() {
      this.loading = true;
      await Dashboard.setChavePix(this.chavePixAlterar);
      await this.carregarChavePix();
      this.confirmDialog = false;
      this.loading = false;
    }
  }
}
</script>

<style lang="sass" scoped>

.title
  @apply text-gray-700 text-sm text-center font-bold mb-2 ml-4
  @screen lg
    @apply text-left text-base

</style>


